import http from '@/services/http-client';
import routes from '@/../config/env';

const { healthAggregator } = routes;

export const productStatusManualOverride = (payload) => http({
  method: 'post',
  url: `${healthAggregator}/health/${payload.operatorId}/product/${payload.product}/override`,
  data: {
    status: payload.status,
  },
});

export const resetProductStatusManualOverride = (payload) => http({
  method: 'delete',
  url: `${healthAggregator}/health/${payload.operatorId}/product/${payload.product}/override`,
});
